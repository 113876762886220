import './Games.css';
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

function Games(props) {
  return (
    <div className="Games">
      <h1>Games</h1>
      <Container>
        <Row>
          <Col xs="4">
            <img className="team-image"/>
          </Col>
          <Col xs="8">
              <h2>Maze Game</h2>
              <a href="https://maze.radioactivemosquito.com">Maze Game Coming Soon</a>
          </Col>
        </Row>
    <Row>
          <Col xs="4">
            <img className="team-image" src="family-matching-game-temp-logo.jpg.jpg" />
          </Col>
          <Col xs="8">
              <h2>Matching Game</h2>
              <a href="https://matching.radioactivemosquito.com">Play Matching Game</a>
          </Col>
        </Row>
    
    
        <Row>
          <Col xs="4">
           
          </Col>
          <Col xs="8">
              <h2>3D Colosseum Game: Coming soon</h2>
      
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Games;
