import './App.css';
import NavBar from './navbar/NavBar';
import Team from './team/Team'
import Games from './games/Games'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Switch>
          <Route exact path="/">
            <h1>Radioactive Mosquito!!</h1>
            <img src="/logo.jpg" className="App-logo" alt="logo" />
          </Route>

          <Route path="/games">
            <Games />
          </Route>
          
          <Route path="/team">
            <Team />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
