import './Team.css';
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

function Team(props) {
  return (
    <div className="Team">
      <h1>Team</h1>
      <Container>
        <Row>
          <Col xs="4">
            <img className="team-image" src="yoshi.jpeg" />
          </Col>
          <Col xs="8">
            <b>Name:</b><p>Kyle</p>
            <b>Bio:</b><p>The Ringleader.</p>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <img className="team-image" src="luke.jpeg.jpg" />
          </Col>
          <Col xs="8">
            <b>Name:</b><p>Luke</p>
            <b>Bio:</b><p>The Trombonist With The Coolest Face</p>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <img className="team-image" src="noah.jpg" />
          </Col>
          <Col xs="8">
            <b>Name:</b><p>Noah</p>
            <b>Bio:</b><p>The Young One</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Team;
