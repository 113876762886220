import './NavBar.css';
import {
  Link
} from "react-router-dom";

function NavBar() {
    const isActive = false
    return (
        <nav className="nav">
            <ul className="inner">
                <div className="menu">
                    <li className={isActive ? 'aactive' : 'mli'}>
                        <Link className="nav-item" to="/">Home</Link>
                    </li>
                    <li className={isActive ? 'aactive' : 'mli'}>
                        <Link className="nav-item" to="/games">Games</Link>
                    </li>
                    <li className={isActive ? 'aactive' : 'mli'}>
                        <Link className="nav-item" to="/team">Team</Link>
                    </li>
                </div>
            </ul>
        </nav>
    );
}

export default NavBar;
